<template>
    <div>
        <transition name="fade-transform" mode="out-in">
            <router-view :key="$route.fullPath"></router-view>
        </transition>
    </div>
</template>
<script>
export default {
    metaInfo() {
		return {
			title: '基本信息-个人中心-文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: '基本信息-个人中心-文物出版社'
                },
                {
                    name: 'description',
					content: '基本信息-个人中心-文物出版社'
                }
            ]
		}
	},
}
</script>